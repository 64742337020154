export default theme => ({
  root: {
    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  }
});